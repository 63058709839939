import React, { Component } from 'react';
import '../desktop.css';
//#region Import Application Parts
import { Accidents } from './application/Accidents';
import { ApplicationAgreement } from './application/ApplicationAgreement';
import { BackgroundCheck } from './application/BackgroundCheck';
import { CreateApplication } from './application/CreateApplication';
import { EmergencyContact } from './application/EmergencyContact';
import { Employers } from './application/Employers';
import { Experience } from './application/Experience';
import { GeneralInfo } from './application/GeneralInfo';
import { LicenseInfo } from './application/LicenseInfo';
import { PersonalHistory } from './application/PersonalHistory';
import { PhysicalHistory } from './application/PhysicalHistory';
import { ReferenceCheck } from './application/ReferenceCheck';
import { ReturnApplication } from './application/ReturnApplication';
import { Signature } from './application/Signature';
import { Submit } from './application/Submit';
import { TrafficViolations } from './application/TrafficViolations';

//#endregion Import Application Parts
import { Button, Card, Col, Container, Dropdown, Row } from 'react-bootstrap';
import { sendDeleteFetch, sendGetFetch, sendPostFetch, sendPutFetch } from './utilities/FetchFunctions';
import { browserName, mobileVendor, mobileModel, isDesktop } from 'react-device-detect';
import { format } from 'date-fns';
import checkmark from '../media/green_checkmark.png';
import { NavMenu } from './NavMenu';
import { initial_consent, initial_mailgun_user, initial_session } from './dtos/InitialValues';
import { ReportApplicantIssue } from './modals/ReportApplicantIssue';

//#region Initial Values
import {
    initial_agreement,
    initial_application,
    initial_background,
    initial_education,
    initial_emergency,
    initial_experience,
    initial_fair_credit,
    initial_general,
    initial_license,
    initial_personal,
    initial_physical,
    initial_reference,
    initial_signature,
    initial_status,
    initial_truck,
} from './dtos/InitialValues';
//#endregion Initial Values

export class Applicant extends Component {
    static displayName = Applicant.name;
    constructor(props) {
        super(props);
        this.state = {
            button_clicked: false,
            multi_button: '',
            view_page: '',
            app_id: -1,
            current_application: initial_application,
            general_info: initial_general,

            driver_license_file: undefined,
            driver_license_file_back: undefined,
            truck_front_file: undefined,
            truck_back_file: undefined,
            truck_left_file: undefined,
            truck_right_file: undefined,
            truck_info: initial_truck,
            personal_history: initial_personal(-1, ''),
            emergency_contact: initial_emergency,
            education: initial_education,
            physical_history: initial_physical,
            employers: [],
            employment_gaps: [],
            license_info: initial_license,
            experience: initial_experience,
            accidents: [],
            traffic_violations: [],
            application_agreement: initial_agreement,
            reference_check: initial_reference,
            fair_credit: initial_fair_credit,
            background_check: initial_background,
            signature_consent: initial_consent,

            login_finished: true,
            error_message: '',
            signature: initial_signature,
            status_id: initial_status,
            show_modal: false,
            send_error_clicked: false,
            mailgun_user: initial_mailgun_user,
            loading: true,
            recruiters: [],
            current_session: initial_session,
            state_provs: [],
            email_already_exists: '',
            traffic_states: [],
        };
        this.clearEntryForm = this.clearEntryForm.bind(this);
        this.setIssue = this.setIssue.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
    }

    logoutApplicant() {
        localStorage.clear();
        window.location.pathname = '/';
        this.setState({ loading: false, current_application: initial_application });
    }

    async componentDidMount() {
        if (localStorage.getItem('today') !== format(new Date(), 'yyyy-MM-dd')) {
            localStorage.clear();
            this.setState({ loading: false, current_application: initial_application });
        } else {
            if (localStorage.getItem('session_id') && localStorage.getItem('session_id') !== 'null') {
                const current_app_id = localStorage.getItem('app_id');
                const current_session_id = localStorage.getItem('session_id');
                await this.GetSession(current_session_id, current_app_id);
            }
        }

        await sendGetFetch('recruiter/GetRecruitersForApp').then((res) => {
            if (res === 'open issue') {
                this.setIssue('Could not load list of recruiters.');
            } else {
                this.setState({ recruiters: res });
            }
        });
    }

    async GetSession(session_id, app_id) {
        await sendGetFetch('driversession/GetDriverSession/' + session_id + '/' + app_id).then((res) => {
            if (res === 'open issue') {
                this.setIssue('Could not find session.');
            } else {
                if (res.App_id === 0) {
                    this.setState({ loading: false });
                } else {
                    const current_fullname = res.Full_Name ? res.Full_Name.split(' ') : '';
                    let first = '';
                    let middle = '';
                    let last = '';
                    if (current_fullname.length === 1) {
                        last = current_fullname[0];
                    } else if (current_fullname.length === 2) {
                        first = current_fullname[0];
                        last = current_fullname[1];
                    } else if (current_fullname.length >= 3) {
                        first = current_fullname[0];
                        middle = current_fullname[1];
                        last = current_fullname[2];
                    }

                    const current_user = {
                        Id: app_id,
                        Email: res.Email,
                        Password: '',
                        New_Password: '',
                        Hired: res.Hired,
                        Hired_By: '',
                        Country: res.Country,
                        Full_Name: res.Full_Name,
                        Status_id: res.Status_id,
                        Reviewed_By: res.Reviewed_By,
                        Date_Started: res.Date_Started,
                        Date_Reviewed: res.Date_Reviewed,
                        Date_Submitted: res.Date_Submitted,
                        Application_Open: '',
                        Position: '',
                        First_Name: first,
                        Middle_Name: middle,
                        Last_Name: last,
                        Employment_Completed: res.Employment_Completed,
                        Has_Accidents: res.Has_Accidents,
                        Has_Violations: res.Has_Violations,
                    };
                    sendGetFetch('stateprovince/' + res.Country).then((states) =>
                        this.setState({ state_provs: states }),
                    );
                    sendGetFetch('stateprovince/GetAllStates').then((states) =>
                        this.setState({ traffic_states: states }),
                    );
                    this.setState({
                        app_id: app_id,
                        current_application: current_user,
                        personal_history: initial_personal(app_id, current_user.Full_Name),
                    });
                    this.populateApplication(app_id, res.Email);
                }
            }
        });
    }

    async populateGeneralInfo(app_id) {
        await sendGetFetch('generalinfo/GetGeneralInfo/' + app_id).then((res) => {
            if (res === 'open issue') {
                this.setState({ general_info: initial_general });
            } else {
                if (res.Id === 0) {
                    this.setState({ general_info: initial_general });
                } else {
                    this.setState({ general_info: res });
                    if (res.License_Copy_id !== '' && res.License_Copy_id !== null) {
                        sendGetFetch('attachment/GetAttachment/' + res.License_Copy_id + '/' + app_id).then((data) => {
                            if (data === 'open issue') {
                                this.setIssue('Could not load Front License Image.');
                            } else {
                                this.setState({ driver_license_file: data.File_Bit_Array });
                            }
                        });
                    }
                    if (res.License_Copy_id_Back !== '' && res.License_Copy_id_Back !== null) {
                        sendGetFetch('attachment/GetAttachment/' + res.License_Copy_id_Back + '/' + app_id).then(
                            (data) => {
                                if (data === 'open issue') {
                                    this.setIssue('Could not load Back License Image.');
                                } else {
                                    this.setState({ driver_license_file_back: data.File_Bit_Array });
                                }
                            },
                        );
                    }
                }
            }
        });
    }

    async populateTruckInfo(app_id) {
        await sendGetFetch('truckinfo/GetTruckInfo/' + app_id).then((res) => {
            if (res === 'open issue') {
                this.setIssue('Could not load Truck Information.');
            } else {
                if (res.Id === 0) {
                    this.setState({ truck_info: initial_truck });
                } else {
                    this.setState({ truck_info: res });
                    if (res.Front_Image_id !== '' && res.Front_Image_id !== null) {
                        sendGetFetch('attachment/GetAttachment/' + res.Front_Image_id + '/' + app_id).then((data) => {
                            if (data === 'open issue') {
                                this.setIssue('Could not load front image of truck.');
                            } else {
                                this.setState({ truck_front_file: data.File_Bit_Array });
                            }
                        });
                    }
                    if (res.Back_Image_id !== '' && res.Back_Image_id !== null) {
                        sendGetFetch('attachment/GetAttachment/' + res.Back_Image_id + '/' + app_id).then((data) => {
                            if (data === 'open issue') {
                                this.setIssue('Could not load back image of truck.');
                            } else {
                                this.setState({ truck_back_file: data.File_Bit_Array });
                            }
                        });
                    }
                    if (res.Right_Image_id !== '' && res.Right_Image_id !== null) {
                        sendGetFetch('attachment/GetAttachment/' + res.Right_Image_id + '/' + app_id).then((data) => {
                            if (data === 'open issue') {
                                this.setIssue('Could not load right image of truck.');
                            } else {
                                this.setState({ truck_right_file: data.File_Bit_Array });
                            }
                        });
                    }
                    if (res.Left_Image_id !== '' && res.Left_Image_id !== null) {
                        sendGetFetch('attachment/GetAttachment/' + res.Left_Image_id + '/' + app_id).then((data) => {
                            if (data === 'open issue') {
                                this.setIssue('Could not load left image of truck.');
                            } else {
                                this.setState({ truck_left_file: data.File_Bit_Array });
                            }
                        });
                    }
                }
            }
        });
    }

    async populateApplication(app_id, email) {
        await sendGetFetch('signature/GetConsent/' + app_id).then((res) => {
            this.setState({ signature_consent: res });
        });
        await sendGetFetch('attachment/GetAttachment/signature_' + app_id + '.png/' + app_id).then((res) =>
            this.setState({ signature: res.App_id === 0 ? initial_signature : res }),
        );
        await this.populateGeneralInfo(app_id);
        await this.populateTruckInfo(app_id);
        await sendGetFetch('personalhistory/GetPersonalHistory/' + app_id).then((res) =>
            this.setState({
                personal_history:
                    res.Id === 0 ? initial_personal(app_id, this.state.current_application.Full_Name) : res,
            }),
        );
        await sendGetFetch('emergencycontact/GetEmergencyContact/' + app_id).then((res) =>
            this.setState({ emergency_contact: res.Id === 0 ? initial_emergency : res }),
        );
        await sendGetFetch('physicalhistory/GetPhysicalHistory/' + app_id).then((res) =>
            this.setState({ physical_history: res.Id === 0 ? initial_physical : res }),
        );
        await sendGetFetch('employers/GetEmployers/' + app_id).then((res) => {
            if (res.length > 0) {
                const newest = res
                    .filter((s) => s.Current_Employer === 'Y')
                    .sort((a, b) => new Date(a.Hire_Date) - new Date(b.Hire_Date));
                const oldest = res
                    .filter((s) => s.Current_Employer === 'N')
                    .sort((a, b) => new Date(a.Terminate_Date) - new Date(b.Terminate_Date));
                this.setState({ employers: oldest.concat(newest) });
            }
        });
        await sendGetFetch('employmentgap/GetGap/' + app_id).then((res) => this.setState({ employment_gaps: res }));
        await sendGetFetch('licenseinfo/GetLicenseInfo/' + app_id).then((res) =>
            this.setState({ license_info: res.Id === 0 ? initial_license : res }),
        );
        await sendGetFetch('experience/GetExperience/' + app_id).then((res) =>
            this.setState({ experience: res.Id === 0 ? initial_experience : res }),
        );
        await sendGetFetch('accident/GetAccidents/' + app_id).then((res) => this.setState({ accidents: res }));
        await sendGetFetch('trafficviolations/GetViolations/' + app_id).then((res) =>
            this.setState({ traffic_violations: res }),
        );
        await sendGetFetch('driverapp/GetApplicationStatus/' + app_id + '/' + email).then((res) => {
            this.setState({ view_page: res.View_Page, status_id: res, login_finished: true });
        });
        await sendGetFetch('applicationagreement/GetApplicationAgreement/' + app_id).then((res) =>
            this.setState({ application_agreement: res.Id === 0 ? initial_agreement : res }),
        );
        await sendGetFetch('referencecheck/GetReferenceCheck/' + app_id).then((res) =>
            this.setState({ reference_check: res.Id === 0 ? initial_reference : res }),
        );
        await sendGetFetch('backgroundreport/GetBackgroundReport/' + app_id).then((res) =>
            this.setState({ background_check: res.Id === 0 ? initial_background : res }),
        );
        this.setState({ loading: false });
    }

    async LoginUser() {
        try {
            this.setState({ login_finished: false });
            const currentApp = this.state.current_application;
            const login_creds = { Email: currentApp.Email, Password: currentApp.Password };
            const putResponse = await fetch('driverapp/GetDriverApp', {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(login_creds),
            });
            const application_data = await putResponse.json();

            if (application_data.Id === 0) {
                this.setState({ app_id: -1, error_message: 'Login Credentials Incorrect', login_finished: true });
            } else {
                this.setState({
                    current_application: application_data,
                    app_id: application_data.Id,
                    error_message: '',
                });

                const postResponse = await fetch('driversession', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(application_data),
                });
                const session_data = await postResponse.json();
                if (session_data.App_id === 0) {
                    this.setIssue('Session could not be established.');
                    this.setState({
                        app_id: -1,
                        error_message: 'Application Error, please report this issue.',
                        login_finished: true,
                    });
                } else {
                    this.setState({ current_session: session_data });
                    localStorage.setItem('app_id', session_data.App_id);
                    localStorage.setItem('session_id', session_data.Id);
                    localStorage.setItem('today', format(new Date(), 'yyyy-MM-dd'));
                    this.GetSession(session_data.Id, session_data.App_id);
                }
            }
        } catch (e) {
            console.log(e);
            this.setState({
                app_id: -1,
                error_message:
                    'Application Issue. Try refreshing the page. If that does not fix the issue, please submit this issue using the report error link below.',
                login_finished: true,
            });
        }
    }

    async CreateUser(current_application) {
        await sendPostFetch(JSON.stringify(current_application), 'driverapp').then((res) => {
            if (res === 'open issue') {
                this.setIssue('Application could not be created.');
            } else if (res === 'An application has already been started with that email address.') {
                this.setState({
                    email_already_exists: 'An application has already been started with that email address.',
                });
            } else {
                sendGetFetch('stateprovince/' + res.Country).then((states) => {
                    this.setState({ state_provs: states });
                });
                sendGetFetch('stateprovince/GetAllStates').then((states) => this.setState({ traffic_states: states }));
                this.setState({ current_application: res, app_id: res.Id });
                sendPostFetch(JSON.stringify(res), 'driversession').then((data) => {
                    if (data === 'open issue') {
                        this.setIssue('Session could not be established.');
                    } else {
                        this.setState({ current_session: data });
                        localStorage.setItem('app_id', data.App_id);
                        localStorage.setItem('session_id', data.Id);
                        localStorage.setItem('today', format(new Date(), 'yyyy-MM-dd'));
                        this.GetSession(data.Id, data.App_id);
                        const current_status = initial_status;
                        current_status.View_Page = 'signature';
                        current_status.Status_id = 1;
                        current_status.App_id = data.App_id;
                        current_status.Email = data.Email;
                        sendPostFetch(JSON.stringify(current_status), 'driverapp/AssignApplicationStatus').then(
                            (data) => {
                                if (data === 'open issue') {
                                    this.setIssue('Could not update application status ID after application creation.');
                                } else {
                                    this.setState({
                                        status_id: current_status,
                                        view_page: 'signature',
                                        email_already_exists: '',
                                        multi_button: '',
                                    });
                                }
                            },
                        );
                    }
                });
            }
        });
    }

    clearEntryForm() {
        const currentApplication = this.state.current_application;
        currentApplication.Full_Name = '';
        currentApplication.Country = '';
        currentApplication.Email = '';
        currentApplication.Password = '';
        currentApplication.New_Password = '';
        currentApplication.Reviewed_By = '';
        this.setState({
            current_application: currentApplication,
            view_page: '',
            error_message: '',
            login_finished: true,
            loading: false,
        });
    }

    //#region Add to Application
    async Log_Error(prop) {
        try {
            let device_data = 'Desktop'
            if (!isDesktop) {
                device_data = `${mobileVendor} ${mobileModel}`;
            }
            const body = {
                app_id: this.state.app_id,
                error: prop,
                device_info: device_data,
                browser: browserName
            }
            await sendPostFetch(JSON.stringify(body), 'ErrorLog').then((res) => {
                console.log(res)
            })
        } catch (e) {
            console.log(e)
        }
    
    }


    async UploadAttachment(prop) {
        const uploadType = prop.uploadType;
        const currentApplication = this.state.current_application;
        var fileName = currentApplication.Last_Name + '_' + this.state.app_id + '_' + uploadType;
        var generalInfo = this.state.general_info;
        generalInfo.App_id = this.state.app_id;
        var truckInfo = this.state.truck_info;
        const body = {
            App_id: this.state.app_id,
            File_Name: fileName,
            Date_Create: '',
            File_Bit_Array: prop.base64string,
            Signature_Type: '',
        };
        await sendPostFetch(JSON.stringify(body), 'attachment/AddAttachment').then((data) => {
            if (data === 'open issue') {
                let error_body = `Could not upload ${prop.uploadType}`;
                error_body = `${error_body} [uploadType: ${uploadType}]`
                error_body = `${error_body} [fileName: ${fileName}]`
                error_body = `${error_body} [fileLength: ${prop.base64string.length}]`
                this.Log_Error(error_body)
            } else {
                if (prop.uploadType.includes('license_copy')) {
                    generalInfo.License_Copy_id = fileName;
                } else if (prop.uploadType.includes('license_back')) {
                    generalInfo.License_Copy_id_Back = fileName;
                } else if (prop.uploadType.includes('front_truck')) {
                    truckInfo.Front_Image_id = fileName;
                } else if (prop.uploadType.includes('back_truck')) {
                    truckInfo.Back_Image_id = fileName;
                } else if (prop.uploadType.includes('left_truck')) {
                    truckInfo.Left_Image_id = fileName;
                } else if (prop.uploadType.includes('right_truck')) {
                    truckInfo.Right_Image_id = fileName;
                }
                this.setState({ general_info: generalInfo, truck_info: truckInfo });
            }
        });
    }

    //#endregion Add to Application

    setIssue(issue) {
        var c_mail_user = this.state.mailgun_user;
        c_mail_user.Issue = issue;
        this.setState({ show_modal: true, mailgun_user: c_mail_user, multi_button: '' });
    }

    async updateStatus(new_status_id, new_view_page, changePage) {
        const currentApp = this.state.current_application;
        currentApp.Status_id = new_status_id;
        await sendPutFetch(JSON.stringify(currentApp), 'driverapp/UpdateApplicationStatus').then((data) => {
            if (data === 'open issue') {
                this.setIssue('Could not update application status.');
            } else {
                this.setState({ current_application: currentApp });
                localStorage.setItem('status', currentApp.Status_id);
            }
        });

        const current_status = this.state.status_id;
        current_status.View_Page = new_view_page;
        current_status.Status_id = parseInt(this.state.status_id.Status_id) + 1;
        await sendPutFetch(JSON.stringify(current_status), 'driverapp/UpdateApplicationStatusId').then((data) => {
            if (data === 'open issue') {
                this.setIssue('Could not update application status ID.');
            } else {
                this.setState({ status_id: current_status });
            }
        });

        if (changePage === 'Y') {
            this.setState({ view_page: new_view_page });
        }
    }

    render() {
        const {
            recruiters,
            multi_button,
            loading,
            view_page,
            app_id,
            current_application,
            accidents,
            application_agreement,
            background_check,
            education,
            emergency_contact,
            employers,
            employment_gaps,
            experience,
            fair_credit,
            general_info,
            license_info,
            personal_history,
            physical_history,
            reference_check,
            signature,
            traffic_violations,
            truck_info,
            login_finished,
            error_message,
            driver_license_file,
            driver_license_file_back,
            truck_front_file,
            truck_back_file,
            truck_left_file,
            truck_right_file,
            status_id,
            show_modal,
            send_error_clicked,
            mailgun_user,
            signature_consent,
            state_provs,
            email_already_exists,
            traffic_states,
        } = this.state;

        //#region Progress Menu
        const progressMenu = (
            <Dropdown>
                <Dropdown.Toggle variant="dark" id="application-progress-dropdown">
                    Application Progress
                </Dropdown.Toggle>
                <Dropdown.Menu className="navigation-dropdown" variant="dark">
                    <Dropdown.Item
                        active={view_page === 'signature'}
                        onClick={() => this.setState({ view_page: 'signature' })}
                    >
                        <label>Create Signature</label>
                        {signature.App_id > 0 ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'general'}
                        onClick={() => this.setState({ view_page: 'general' })}
                    >
                        <label>General Information</label>
                        {general_info.App_id > 0 ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'personal'}
                        onClick={() => this.setState({ view_page: 'personal' })}
                    >
                        <label>Personal History</label>
                        {personal_history.App_id > 0 ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'emergency'}
                        onClick={() => this.setState({ view_page: 'emergency' })}
                    >
                        <label>Emergency Contact</label>
                        {emergency_contact.App_id > 0 ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>

                    <Dropdown.Item
                        active={view_page === 'physical'}
                        onClick={() => this.setState({ view_page: 'physical' })}
                    >
                        <label>Physical History</label>
                        {physical_history.App_id > 0 ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'employers'}
                        onClick={() => this.setState({ view_page: 'employers' })}
                    >
                        <label>Employment History</label>
                        {current_application.Employment_Completed === 'Y' ||
                        current_application.Employment_Completed === 'U' ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'license'}
                        onClick={() => this.setState({ view_page: 'license' })}
                    >
                        <label>License Information</label>
                        {license_info.App_id > 0 ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'experience'}
                        onClick={() => this.setState({ view_page: 'experience' })}
                    >
                        <label>Experience</label>
                        {experience.App_id > 0 ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'accident'}
                        onClick={() => this.setState({ view_page: 'accident' })}
                    >
                        <label>Accidents</label>
                        {current_application.Has_Accidents ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'traffic'}
                        onClick={() => this.setState({ view_page: 'traffic' })}
                    >
                        <label>Traffic Violations</label>
                        {current_application.Has_Violations ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'agreement'}
                        onClick={() => this.setState({ view_page: 'agreement' })}
                    >
                        <label>Application Agreement</label>
                        {application_agreement.App_id > 0 ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'reference'}
                        onClick={() => this.setState({ view_page: 'reference' })}
                    >
                        <label>Reference Check</label>
                        {reference_check.App_id > 0 ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'background'}
                        onClick={() => this.setState({ view_page: 'background' })}
                    >
                        <label>Background Report</label>
                        {background_check.App_id > 0 ? (
                            <img
                                style={{ width: '1rem', height: '1rem', alignSelf: 'center', marginLeft: '0.5rem' }}
                                src={checkmark}
                                alt="completed_field"
                            />
                        ) : null}
                    </Dropdown.Item>
                    <Dropdown.Item
                        active={view_page === 'submit'}
                        onClick={() => this.setState({ view_page: 'submit' })}
                    >
                        <label>Submit Application</label>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );

        //#endregion Progress Menu

        //#region Application Parts
        const application_parts =
            view_page !== '' ? (
                <Container fluid={true} className="px-0">
                    <Row xs={12}>
                        <Col xs={12}>
                            {view_page === 'create' ? (
                                <CreateApplication
                                    click_button={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    clearApp={() => this.clearEntryForm()}
                                    current_application={current_application}
                                    app_id={app_id}
                                    onupdate={(prop) =>
                                        this.setState({ current_application: prop, button_clicked: false })
                                    }
                                    onsave={async () => await this.CreateUser(current_application)}
                                    recruiters={recruiters}
                                    email_already_exists={email_already_exists}
                                />
                            ) : null}

                            {view_page === 'return' ? (
                                <ReturnApplication
                                    clearApp={() => this.clearEntryForm()}
                                    current_application={current_application}
                                    login_finished={login_finished}
                                    error_message={error_message}
                                    onupdate={(prop) => this.setState({ current_application: prop })}
                                    onsave={async () => await this.LoginUser()}
                                />
                            ) : null}

                            {view_page === 'signature' ? (
                                <Signature
                                    click_delete={() => this.setState({ multi_button: 'delete' })}
                                    click_save={() => this.setState({ multi_button: 'save' })}
                                    click_clear={() => this.setState({ multi_button: '' })}
                                    button_clicked={multi_button}
                                    status_id={status_id.Status_id}
                                    full_name={current_application.Full_Name}
                                    handleUpdate={(prop) => this.setState({ signature: JSON.parse(prop) })}
                                    signature={signature}
                                    app_id={app_id}
                                    signature_consent={signature_consent}
                                    onPostConsent={async () =>
                                        await sendPostFetch(
                                            JSON.stringify(signature_consent),
                                            'signature/AddConsent',
                                        ).then((res) => {})
                                    }
                                    onUpdateConsent={(prop) => this.setState({ signature_consent: JSON.parse(prop) })}
                                    onPost={async (prop) =>
                                        await sendPostFetch(prop, 'attachment/AddAttachment').then((res) => {
                                            this.updateStatus('Signature Created', 'general', 'Y');
                                        })
                                    }
                                    onPut={async (prop) =>
                                        await sendPutFetch(prop, 'attachment/UpdateAttachment').then((res) => {
                                            this.setState({ view_page: 'general' });
                                        })
                                    }
                                    updateAttachment={async (prop) =>
                                        await sendPutFetch(JSON.stringify(prop), 'attachment/UpdateAttachment').then(
                                            (res) => {
                                                this.setState({
                                                    view_page: 'general',
                                                    signature: res,
                                                    multi_button: '',
                                                });
                                            },
                                        )
                                    }
                                    onDelete={async () =>
                                        await sendGetFetch(
                                            'attachment/DeleteAttachment/' +
                                                signature.File_Name +
                                                '/' +
                                                signature.App_id,
                                        ).then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('Signature could not be deleted.');
                                            } else {
                                                this.setState({ signature: initial_signature, multi_button: '' });
                                            }
                                        })
                                    }
                                    onNext={() => this.setState({ view_page: 'general' })}
                                />
                            ) : null}
                            {view_page === 'general' ? (
                                <GeneralInfo
                                    setView={(prop) => this.setState({ view_page: prop })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    recruiters={recruiters}
                                    country={current_application.Country}
                                    state_provs={state_provs}
                                    current_recruiter={current_application.Reviewed_By}
                                    updateRecruiter={(prop) => {
                                        const c_application = current_application;
                                        c_application.Reviewed_By = prop;
                                        this.setState({ current_application: c_application });
                                    }}
                                    putRecruiter={async () =>
                                        await sendPutFetch(
                                            JSON.stringify(current_application),
                                            '/driverapp/UpdateRecruiter',
                                        ).then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue(
                                                    'Could not update recruiter while adding/updating General Information.',
                                                );
                                            }
                                        })
                                    }
                                    status_id={status_id.Status_id}
                                    general_info={general_info}
                                    app_id={app_id}
                                    driver_license_file={driver_license_file}
                                    update_driver_license_file={(prop) => this.setState({ driver_license_file: prop })}
                                    driver_license_file_back={driver_license_file_back}
                                    update_driver_license_file_back={(prop) =>
                                        this.setState({ driver_license_file_back: prop })
                                    }
                                    truck_front_file={truck_front_file}
                                    update_truck_front_file={(prop) => this.setState({ truck_front_file: prop })}
                                    truck_back_file={truck_back_file}
                                    update_truck_back_file={(prop) => this.setState({ truck_back_file: prop })}
                                    truck_left_file={truck_left_file}
                                    update_truck_left_file={(prop) => this.setState({ truck_left_file: prop })}
                                    truck_right_file={truck_right_file}
                                    update_truck_right_file={(prop) => this.setState({ truck_right_file: prop })}
                                    setIssue={(prop) => this.setIssue(prop)}
                                    uploadattachment={async (prop) => await this.UploadAttachment(prop)}
                                    truck_info={truck_info}
                                    onupdateTruck={(prop) => this.setState({ truck_info: prop })}
                                    onupdate={(prop) => this.setState({ general_info: prop })}
                                    onPost={async (prop) =>
                                        await sendPostFetch(JSON.stringify(general_info), 'generalinfo').then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('Could not add General Info.');
                                            } else {
                                                this.updateStatus('General Info Completed', 'personal', 'N');
                                            }
                                        })
                                    }
                                    onPut={async () =>
                                        await sendPutFetch(JSON.stringify(general_info), 'generalinfo').then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('Could not update General Information.');
                                            }
                                        })
                                    }
                                    onPostTruck={async () =>
                                        await sendPostFetch(JSON.stringify(truck_info), 'truckinfo').then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('Could not add Truck Information.');
                                            }
                                        })
                                    }
                                    onPutTruck={async () =>
                                        await sendPutFetch(JSON.stringify(truck_info), 'truckinfo').then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('Could not update Truck Information');
                                            }
                                        })
                                    }
                                />
                            ) : null}

                            {view_page === 'personal' ? (
                                <PersonalHistory
                                    setView={(prop) => this.setState({ view_page: prop })}
                                    onNext={() => this.setState({ view_page: 'emergency' })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    personal_history={personal_history}
                                    Full_Name={current_application.Full_Name}
                                    country={current_application.Country}
                                    app_id={app_id}
                                    state_provs={state_provs}
                                    status_id={status_id.Status_id}
                                    onupdate={(prop) => this.setState({ personal_history: prop })}
                                    onPost={async () =>
                                        await sendPostFetch(JSON.stringify(personal_history), 'personalhistory').then(
                                            (res) => {
                                                if (res === 'open issue') {
                                                    this.setIssue('Could not save Personal History');
                                                } else {
                                                    this.updateStatus('Personal History Completed', 'emergency', 'N');
                                                }
                                            },
                                        )
                                    }
                                    onPut={async () =>
                                        await sendPutFetch(JSON.stringify(personal_history), 'personalhistory').then(
                                            (res) => {
                                                console.log(res);
                                                if (res === 'open issue') {
                                                    this.setIssue('Personal History could not be updated.');
                                                }
                                            },
                                        )
                                    }
                                />
                            ) : null}

                            {view_page === 'emergency' ? (
                                <EmergencyContact
                                    setView={(prop) => this.setState({ view_page: prop })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    emergency_contact={emergency_contact}
                                    app_id={app_id}
                                    status_id={status_id.Status_id}
                                    onupdate={(prop) => this.setState({ emergency_contact: prop })}
                                    onPost={async () =>
                                        await sendPostFetch(JSON.stringify(emergency_contact), 'emergencycontact').then(
                                            (res) => {
                                                if (res === 'open issue') {
                                                    this.setIssue('Could not add emergency contact');
                                                } else {
                                                    this.updateStatus('Emergency Contact Completed', 'physical', 'N');
                                                }
                                            },
                                        )
                                    }
                                    onPut={async () =>
                                        await sendPutFetch(JSON.stringify(emergency_contact), 'emergencycontact').then(
                                            (res) => {
                                                if (res === 'open issue') {
                                                    this.setIssue('Could not update emergency contact.');
                                                }
                                            },
                                        )
                                    }
                                />
                            ) : null}

                            {view_page === 'physical' ? (
                                <PhysicalHistory
                                    setView={(prop) => this.setState({ view_page: prop })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    physical_history={physical_history}
                                    app_id={app_id}
                                    status_id={status_id.Status_id}
                                    onBack={() => this.setState({ view_page: 'emergency' })}
                                    onNext={() => this.setState({ view_page: 'employers' })}
                                    onupdate={(prop) => this.setState({ physical_history: prop })}
                                    onPost={async () =>
                                        await sendPostFetch(JSON.stringify(physical_history), 'physicalhistory').then(
                                            (res) => {
                                                if (res === 'open issue') {
                                                    this.setIssue('Physical History could not be added.');
                                                } else {
                                                    this.updateStatus('Physical History Completed', 'employers', 'N');
                                                }
                                            },
                                        )
                                    }
                                    onPut={async () =>
                                        await sendPutFetch(JSON.stringify(physical_history), 'physicalhistory').then(
                                            (res) => {
                                                if (res === 'open issue') {
                                                    this.setIssue('Physical History could not be updated.');
                                                }
                                            },
                                        )
                                    }
                                />
                            ) : null}

                            {view_page === 'employers' ? (
                                <Employers
                                    onBack={() => this.setState({ view_page: 'physical' })}
                                    onNext={() => this.setState({ view_page: 'license' })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    employers={employers}
                                    updateEmployerList={(prop) => this.setState({ employers: prop })}
                                    updateGapList={(prop) => this.setState({ employment_gaps: prop })}
                                    current_application={current_application}
                                    update_application={(prop) => this.setState({ current_application: prop })}
                                    employment_gaps={employment_gaps}
                                    app_id={app_id}
                                    status_id={status_id.Status_id}
                                    setIssue={(prop) => this.setIssue(prop)}
                                    onStatusUpdate={() =>
                                        this.updateStatus('Employment History Completed', 'license', 'N')
                                    }
                                />
                            ) : null}
                            {view_page === 'license' ? (
                                <LicenseInfo
                                    setView={(prop) => this.setState({ view_page: prop })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    license_info={license_info}
                                    app_id={app_id}
                                    status_id={status_id.Status_id}
                                    onupdate={(prop) => this.setState({ license_info: prop })}
                                    onPost={async () =>
                                        await sendPostFetch(JSON.stringify(license_info), 'licenseinfo').then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('Could not add License Information');
                                            } else {
                                                this.updateStatus('License Info Completed', 'experience', 'N');
                                            }
                                        })
                                    }
                                    onPut={async () =>
                                        await sendPutFetch(JSON.stringify(license_info), 'licenseinfo').then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('License Information could not be updated.');
                                            }
                                        })
                                    }
                                />
                            ) : null}
                            {view_page === 'experience' ? (
                                <Experience
                                    setView={(prop) => this.setState({ view_page: prop })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    experience={experience}
                                    app_id={app_id}
                                    status_id={status_id.Status_id}
                                    onupdate={(prop) => this.setState({ experience: prop })}
                                    onPost={async () =>
                                        await sendPostFetch(JSON.stringify(experience), 'experience').then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('Could not add Experience');
                                            } else {
                                                this.updateStatus('Experience Completed', 'accident', 'N');
                                            }
                                        })
                                    }
                                    onPut={async () =>
                                        await sendPutFetch(JSON.stringify(experience), 'experience').then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('Could not update Experience');
                                            }
                                        })
                                    }
                                />
                            ) : null}
                            {view_page === 'accident' ? (
                                <Accidents
                                    onBack={() => this.setState({ view_page: 'experience' })}
                                    onNext={() => this.setState({ view_page: 'traffic' })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    updateAccidents={(prop) => this.setState({ accidents: prop })}
                                    accidents={accidents}
                                    current_application={current_application}
                                    update_application={(prop) => this.setState({ current_application: prop })}
                                    app_id={app_id}
                                    status_id={status_id.Status_id}
                                    setIssue={(prop) => this.setIssue(prop)}
                                    onStatusUpdate={() => this.updateStatus('Accidents Completed', 'traffic', 'N')}
                                />
                            ) : null}
                            {view_page === 'traffic' ? (
                                <TrafficViolations
                                    onBack={() => this.setState({ view_page: 'accident' })}
                                    onNext={() => this.setState({ view_page: 'agreement' })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    traffic_states={traffic_states}
                                    multi_button={multi_button}
                                    setIssue={(prop) => this.setIssue(prop)}
                                    onStatusUpdate={() => this.updateStatus('Violations Completed', 'agreement', 'N')}
                                    updateViolations={(prop) => this.setState({ traffic_violations: prop })}
                                    traffic_violations={traffic_violations}
                                    current_application={current_application}
                                    update_application={(prop) => this.setState({ current_application: prop })}
                                    app_id={app_id}
                                    status_id={status_id.Status_id}
                                />
                            ) : null}

                            {view_page === 'agreement' ? (
                                <ApplicationAgreement
                                    setView={(prop) => this.setState({ view_page: prop })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    setIssue={(prop) => this.setIssue(prop)}
                                    application_agreement={application_agreement}
                                    app_id={app_id}
                                    Full_Name={current_application.Full_Name}
                                    signature={signature.File_Bit_Array}
                                    status_id={status_id.Status_id}
                                    onUpdate={(prop) => this.setState({ application_agreement: prop })}
                                    onPost={async () =>
                                        await sendPostFetch(
                                            JSON.stringify(application_agreement),
                                            'applicationagreement',
                                        ).then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('Could not save Application Agreement');
                                            } else {
                                                this.updateStatus('Application Agreement Completed', 'reference', 'N');
                                            }
                                        })
                                    }
                                    onPut={async () =>
                                        await sendPutFetch(
                                            JSON.stringify(application_agreement),
                                            'applicationagreement',
                                        ).then((res) => {
                                            if (res === 'open issue') {
                                                this.setIssue('Could not update Application Agreement');
                                            }
                                        })
                                    }
                                    onDelete={async () =>
                                        await sendDeleteFetch(
                                            JSON.stringify(application_agreement),
                                            'applicationagreement',
                                        ).then((res) => {
                                            this.setState({
                                                application_agreement: initial_agreement,
                                                view_page: 'agreement',
                                            });
                                        })
                                    }
                                />
                            ) : null}

                            {view_page === 'reference' ? (
                                <ReferenceCheck
                                    setView={(prop) => this.setState({ view_page: prop })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    setIssue={(prop) => this.setIssue(prop)}
                                    country={current_application.Country}
                                    reference_check={reference_check}
                                    app_id={app_id}
                                    signature={signature.File_Bit_Array}
                                    personal_history={personal_history}
                                    status_id={status_id.Status_id}
                                    onUpdate={(prop) => this.setState({ reference_check: prop })}
                                    onPost={async () =>
                                        await sendPostFetch(JSON.stringify(reference_check), 'referencecheck').then(
                                            (res) => {
                                                if (res === 'open issue') {
                                                    this.setIssue('Could not save Reference Check');
                                                } else {
                                                    this.updateStatus('Reference Check Completed', 'background', 'N');
                                                }
                                            },
                                        )
                                    }
                                    onPut={async () =>
                                        await sendPutFetch(JSON.stringify(reference_check), 'referencecheck').then(
                                            (res) => {
                                                if (res === 'open issue') {
                                                    this.setIssue('Could not update Reference Check');
                                                }
                                            },
                                        )
                                    }
                                    onDelete={async () =>
                                        await sendDeleteFetch(JSON.stringify(reference_check), 'referencecheck').then(
                                            (res) => {
                                                this.setState({
                                                    reference_check: initial_reference,
                                                    view_page: 'background',
                                                });
                                            },
                                        )
                                    }
                                />
                            ) : null}
                            {view_page === 'background' ? (
                                <BackgroundCheck
                                    setView={(prop) => this.setState({ view_page: prop })}
                                    setMultiButton={(prop) => this.setState({ multi_button: prop })}
                                    multi_button={multi_button}
                                    setIssue={(prop) => this.setIssue(prop)}
                                    background_check={background_check}
                                    app_id={app_id}
                                    status_id={status_id.Status_id}
                                    employers={employers}
                                    signature={signature.File_Bit_Array}
                                    personal_history={personal_history}
                                    onUpdate={(prop) => this.setState({ background_check: prop })}
                                    onPost={async () =>
                                        await sendPostFetch(JSON.stringify(background_check), 'backgroundreport').then(
                                            (res) => {
                                                if (res === 'open issue') {
                                                    this.setIssue('Could not save Background Check');
                                                } else {
                                                    this.updateStatus('Background Report Completed', 'submit', 'N');
                                                }
                                            },
                                        )
                                    }
                                    onPut={async () =>
                                        await sendPutFetch(JSON.stringify(background_check), 'backgroundreport').then(
                                            (res) => {
                                                if (res === 'open issue') {
                                                    this.setIssue('Could not update Background Check');
                                                }
                                            },
                                        )
                                    }
                                    onDelete={async () =>
                                        await sendDeleteFetch(
                                            JSON.stringify(background_check),
                                            'backgroundreport',
                                        ).then((res) => {
                                            this.setState({
                                                background_check: initial_background,
                                                view_page: 'background',
                                            });
                                        })
                                    }
                                />
                            ) : null}
                            {view_page === 'submit' ? (
                                <Submit
                                    current_application={current_application}
                                    loading={loading ? 'T' : 'F'}
                                    signature={signature.File_Bit_Array}
                                    general_info={general_info}
                                    truck_info={truck_info}
                                    personal_history={personal_history}
                                    emergency_contact={emergency_contact}
                                    education={education}
                                    physical_history={physical_history}
                                    employers={employers}
                                    employment_gaps={employment_gaps}
                                    license_info={license_info}
                                    experience={experience}
                                    accidents={accidents}
                                    traffic_violations={traffic_violations}
                                    application_agreement={application_agreement}
                                    reference_check={reference_check}
                                    fair_credit={fair_credit}
                                    background_check={background_check}
                                    driver_license_file={driver_license_file}
                                    driver_license_file_back={driver_license_file_back}
                                    truck_front_file={truck_front_file}
                                    truck_back_file={truck_back_file}
                                    truck_left_file={truck_left_file}
                                    truck_right_file={truck_right_file}
                                    onBack={() => this.setState({ view_page: 'background' })}
                                    set_view_page={(prop) => this.setState({ view_page: prop })}
                                    onPost={async (prop) =>
                                        await sendPutFetch(JSON.stringify(prop), 'driverapp/UpdateDateSubmitted').then(
                                            (res) => {
                                                localStorage.setItem('status', res.Status_id);
                                                localStorage.setItem('date_submitted', res.Date_Submitted);
                                                this.updateStatus('Application Submitted', 'thankyou', 'N');
                                            },
                                        )
                                    }
                                />
                            ) : null}
                            {view_page === 'thankyou' ? (
                                <div className="d-flex flex-column">
                                    <h3>Thank you for submitting your application!</h3>
                                    <h5>A recruiter will be in touch with you soon.</h5>
                                    <h5>
                                        If you wish to contact recruiting now please call
                                        <a href="tel:1-800-263-9117,2536" className="submission-link px-2">
                                            1-800-263-9117 x 2536
                                        </a>
                                        or email
                                        <a className="submission-link px-2" href="mailto:recruiting@scotlynn.com">
                                            recruiting@scotlynn.com
                                        </a>
                                    </h5>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            ) : null;
        //#endregion Application Parts

        //#region Page_Title
        let page_title = 'Employment Application for Company Driver, Owner Operator or Lease Operator';
        if (view_page === 'create') {
            page_title = 'Start New Application';
        } else if (view_page === 'return') {
            page_title = 'Return to Application';
        } else if (view_page === 'signature') {
            page_title = 'Create Signature';
        } else if (view_page === 'general') {
            page_title = 'General Information';
        } else if (view_page === 'personal') {
            page_title = 'Personal History';
        } else if (view_page === 'emergency') {
            page_title = 'Emergency Contact';
        } else if (view_page === 'education') {
            page_title = 'Education';
        } else if (view_page === 'physical') {
            page_title = 'Physical History';
        } else if (view_page === 'employers') {
            page_title = 'Employment History';
        } else if (view_page === 'license') {
            page_title = 'License Information';
        } else if (view_page === 'experience') {
            page_title = 'Experience';
        } else if (view_page === 'accident') {
            page_title = 'Accidents';
        } else if (view_page === 'traffic') {
            page_title = 'Traffic Violations';
        } else if (view_page === 'agreement') {
            page_title = 'TO BE READ AND SIGNED BY APPLICANT';
        } else if (view_page === 'reference') {
            page_title = 'Reference Check';
        } else if (view_page === 'fair_credit') {
            page_title = '';
        } else if (view_page === 'background') {
            page_title = 'THE BELOW DISCLOSURE AND AUTHORIZATION LANGUAGE IS FOR MANDATORY USE BY ALL ACCOUNTHOLDERS';
        } else if (view_page === 'submit') {
            page_title = 'Submit Application';
        }
        //#endregion Page_Title

        //#region Application Options
        const application_options =
            view_page === '' ? (
                <Container fluid="xs" className="mt-4">
                    <Row className="d-flex flex-row justify-content-around mt-3">
                        <Col xl={2} lg={1} sm={0} med={0}></Col>
                        <Col xl={2} lg={3} sm={6} xs={6} med={6}>
                            <Button
                                variant="secondary"
                                onClick={() => this.setState({ view_page: 'create' })}
                                className="btn-op70 w-100"
                            >
                                Start New Application
                            </Button>
                        </Col>
                        <Col xl={2} lg={3} sm={6} xs={6} med={6}>
                            <Button
                                variant="secondary"
                                onClick={() => this.setState({ view_page: 'return' })}
                                className="btn-op70 w-100"
                            >
                                Return to Application
                            </Button>
                        </Col>
                        <Col xl={2} lg={1} sm={0} med={0}></Col>
                    </Row>
                </Container>
            ) : (
                <div className="d-flex justify-content-start">
                    {app_id <= 0 ? null : (
                        <Button onClick={() => this.clearEntryForm()} variant="outline-secondary" className="btn-op70">
                            Back
                        </Button>
                    )}
                </div>
            );
        //#endregion Application Options

        //#region Application Body

        const application_body = (
            <Card className="text-center mt-3">
                <Card.Header as="h4" className="p-3">
                    {page_title}
                </Card.Header>
                <Card.Body className="py-5 d-flex flex-column">
                    {view_page === '' ? (
                        <Card.Title as="h5">
                            Would you like start a new application or return to an existing one?
                        </Card.Title>
                    ) : null}

                    {app_id === -1 ? application_options : null}

                    {application_parts}
                </Card.Body>
                <Card.Footer className="text-muted p-3">
                    <Button variant="link" className="btn-op70" onClick={() => this.setIssue('')}>
                        Report Issue
                    </Button>
                </Card.Footer>
            </Card>
        );
        //#endregion Application Body

        //#region NavMenu
        const navmenu = (
            <NavMenu
                app_id={app_id}
                status_id={status_id.Status_id}
                view_page={view_page}
                application_status={
                    localStorage.getItem('status') === 'Application Submitted'
                        ? 'Application Submitted: ' + current_application.Date_Submitted
                        : 'Application Started: ' + current_application.Date_Started
                }
            />
        );
        //#endregion NavMenu

        //#region Loading Card
        const loading_card = (
            <Card className="text-center mt-3">
                <Card.Header as="h4" className="p-3">
                    Loading...
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                    <Card.Title as="h5">Checking for an existing application... </Card.Title>
                </Card.Body>
                <Card.Footer className="text-muted p-3"></Card.Footer>
            </Card>
        );
        //#endregion Loading Card

        
        return (
            <div className="mb-3">
                {navmenu}
                {app_id > 0 ? (
                    <div className="bg-dark d-flex flex-row px-3 py-1 justify-content-between">
                        {current_application.Date_Submitted !== '' &&
                        current_application.Date_Submitted !== '1/1/1900 12:00:00 AM'
                            ? null
                            : progressMenu}
                    </div>
                ) : null}
                <Container fluid="xs" className="mb-5 mx-3 mt-3">
                
                    <Row className="d-flex flex-row justify-content-center">
                        <Col xs={12} lg={8}>
                            {loading ? loading_card : application_body}
                        </Col>
                    </Row>
                </Container>

                <ReportApplicantIssue
                    show={show_modal}
                    mailgun_user={mailgun_user}
                    updateUser={(prop) => this.setState({ mailgun_user: JSON.parse(prop) })}
                    ignore={() =>
                        this.setState({
                            send_error_clicked: false,
                            show_modal: false,
                            mailgun_user: initial_mailgun_user,
                        })
                    }
                    sendEmail={async () =>
                        await sendPutFetch(JSON.stringify(mailgun_user), '/mailgun').then((res) => {
                            if (res.StatusCode === 200) {
                                this.setState({
                                    send_error_clicked: false,
                                    show_modal: false,
                                    mailgun_user: initial_mailgun_user,
                                });
                            } else {
                                this.setState({
                                    send_error_clicked: false,
                                    show_modal: false,
                                    mailgun_user: initial_mailgun_user,
                                });
                            }
                        })
                    }
                    errorClicked={send_error_clicked}
                    click_error={() => this.setState({ send_error_clicked: !send_error_clicked })}
                />
            </div>
        );
    }
}
