import ConvertApi from 'convertapi-js'


export async function Convert_To_PDF(file) {
    try {

        const convertApi = ConvertApi.auth({ secret: 'xH4v9b4lGOJMoxlv' })
        const params = convertApi.createParams();
        params.add('file', file);
/*        params.add('Presets', 'web');*/
        params.add('StoreFile', 'True');
        const result = await convertApi.convert('pdf', 'compress', params);
        const blob = await fetch(result.dto.Files[0].Url)
            .then((r) => r.blob())
            .then((blobFile) => new File([blobFile], file.name, { type: file.type }));
        return blob;
    } catch (e) {
        console.log(e)
        return null;
    }
}
